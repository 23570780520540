<template>
  <div class="loader-container" v-if="isLoading">
    <span class="loader"></span>
  </div>
  <div id="chart" class="chart financialChart" v-else>
    <apexchart
      type="line"
      height="450"
      :options="financialChartOptions.options"
      :series="financialChartOptions.series"
    ></apexchart>
  </div>
</template>

<script setup>
import { ref, defineProps, onMounted, watch } from "vue";
import { formatCurrencyWithoutSymbol, getMonth } from "@/mixins";
import { ManagerDashboardController } from "@/AppClubCarby/Controller/DashBoardsController";

const isLoading = ref(true);

const managerDashboardService = new ManagerDashboardController(
  props.managerId,
  props.periodStart,
  props.periodEnd,
  props.gasStationId
);

const props = defineProps({
  managerId: {
    type: Number,
    required: true,
  },
  periodStart: {
    type: String,
    required: true,
  },
  periodEnd: {
    type: String,
    required: true,
  },
  gasStationId: {
    type: Number,
    required: true,
  },
});

const chartData = ref([]);

const financialChartOptions = ref({
  series: [
    {
      name: "Receita",
      type: "column",
      data: [],
      color: "#11592E",
    },
    {
      name: "Custo",
      type: "column",
      data: [],
      color: "#FFC145",
    },
    {
      name: "%Custo/Receita",
      type: "line",
      data: [],
      color: "#000",
    },
  ],
  options: {
    chart: {
      height: "100%",
      type: "line",
      stacked: false,
      toolbar: {
        show: false,
      },
      dataLabels: {
        offsetY: -10,
      },
    },
    dataLabels: {
      offsetY: 0,
      background: {
        foreColor: "white",
        borderWidth: 0,
        padding: 5,
      },
      formatter: (val) => {
        return formatCurrencyWithoutSymbol(val);
      },
      style: {
        colors: ["black"],
        fontSize: "12px",
      },
      enabled: true,
    },
    stroke: {
      width: [1, 1, 4],
    },
    title: {
      text: "Análise financeira ClubCarby",
      align: "left",
      offsetX: 10,
      style: {
        fontSize: "18px",
        color: "#11592E",
      },
    },
    labels: [],
    yaxis: [
      {
        seriesName: "Receita",
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: false,
          color: "#11592E",
        },
        labels: {
          style: {
            colors: "#11592E",
          },
        },
        title: {
          text: "",
          style: {
            color: "#11592E",
          },
          show: false,
        },
        tooltip: {
          enabled: true,
        },
      },
      {
        seriesName: "Receita",
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
          color: "black",
        },
        labels: {
          show: false,
        },
      },
      {
        seriesName: "Custo/Receita ",
        opposite: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: false,
          color: "#000",
        },
        labels: {
          style: {
            colors: "#000",
          },
        },
        title: {
          text: "",
          style: {
            color: "#000",
          },
        },
        forceNiceScale: true,
        tickAmount: 4,
      },
    ],
    tooltip: {
      fixed: {
        enabled: false,
        offsetY: 30,
        offsetX: 60,
      },
    },
    legend: {
      horizontalAlign: "center",
      position: "top",
      offsetX: 40,
      markers: {
        radius: 0,
      },
    },
    responsive: [
      {
        breakpoint: 768,
        options: {
          title: {
            style: {
              fontSize: "14px",
              color: "#11592E",
            },
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        dataLabels: {
          position: "top",
        },
      },
    },
  },
  set setLabels(data) {
    this.options.labels = data;
  },
  setSeries(index, data) {
    this.series[index].data = data;
  },
});

const updateFinancialChart = () => {
  const income = chartData.value.map((data) => {
    return data.amount;
  });

  const cost = chartData.value.map((data) => {
    return data.cost;
  });

  const costIncome = chartData.value.map((data) => {
    return data.percentage;
  });

  const financialMonths = chartData.value.map((data) => {
    return getMonth("Full", data.yearMonth[1]);
  });

  financialChartOptions.value.setSeries(0, income);
  financialChartOptions.value.setSeries(1, cost);
  financialChartOptions.value.setSeries(2, costIncome);

  financialChartOptions.value.setLabels = financialMonths.filter((el, pos) => {
    return financialMonths.indexOf(el) == pos;
  });
};

onMounted(async () => {
  getData();
});

const getData = async () => {
  chartData.value = await managerDashboardService.financialAnalysisData();
  updateFinancialChart();
  isLoading.value = false;
};

watch(props, (newVal, oldVal) => {
  if (newVal.toString() != oldVal.toString()) {
    getData();
  }
});
</script>

<style scoped>
.loader-container{
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}
.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #FFF;
  border-bottom-color: #11592E;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}
</style>
